import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
  <Helmet title='Pebble | François Deguire' />
    <ProjectPage
      name='Pebble'
      description={`
        The aim of this personal project was to create a device under the
        theme of connecting people at their homes. Pebble is a mesh Wi-Fi
        solution that integrates a base station and three wireless pebbles
        that extend the signal from the former. This configuration gives the
        users ultimate flexibility in their home Wi-Fi setup by letting them
        daisy chain the pebbles to extend the signal even further. The
        refined design integrates perfectly with contemporary homes so that
        it can proudly be displayed in the open.
      `}
      meta={`
        Personal Project<br>
        In 2020
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    pebble1: file(relativePath: { eq: "pebble/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pebble2: file(relativePath: { eq: "pebble/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pebble3: file(relativePath: { eq: "pebble/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pebble4: file(relativePath: { eq: "pebble/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pebble5: file(relativePath: { eq: "pebble/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pebble6: file(relativePath: { eq: "pebble/6.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
